import { ref } from "vue";
import { defineStore } from "pinia";
import { severityCode } from "@/services/api/httpClient";
import { getParties } from "@/services/api/party.api";

const useCacheStore = defineStore("cache", () => {
  const _parties = ref([]);

  async function parties() {
    let error = undefined;
    if (_parties.value.length == 0) {
      const response = await getParties();
      console.log("useCacheStore, parties ", response);
      if (response.severity <= severityCode.warning) {
        _parties.value = [...response.data.parties];
      } else {
        error = response.error;
      }
    }
    return !error ? { success: true, list: _parties.value } : { success: false, message: error };
  }

  return {
    parties,
  };
});

export { useCacheStore };
