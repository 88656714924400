<script lang="ts" setup>
import { ref, computed, watch, onMounted, getCurrentInstance } from "vue";
import { getUsers } from "@/services/api/iam.api";
import { getPartyRoles } from "@/services/api/masterdata.api";

const emits = defineEmits(["on-add-contact"]);

const props = defineProps({
  readOnly: {
    type: Boolean,
    default: false,
  },
  maxHeight: {
    Type: Number,
    default: undefined,
  },
});

const role = ref({ key: "None" });
const partyRoles = ref([]);
const partyRolesLoaded = ref(false);

const selectedUser = ref(undefined);
const usersLoading = ref(false);
const searchUsers = ref(undefined); // gaan we watchen
const userItems = ref([]); // items voor autocomplete

const menuProps = computed(() => {
  if (props.maxHeight) {
    return { maxHeight: props.maxHeight };
  } else {
    return undefined;
  }
});
const addDisabled = computed(() => role.value.key === "None" || !selectedUser.value || !selectedUser.value.id);

watch(
  () => searchUsers.value,
  () => {
    console.log("ContactRoleSelection, watch searchUsers ", searchUsers.value);
    fillItems(searchUsers.value);
  }
);

onMounted(() => {
  loadPartyRoles();
});

async function fillItems(searchText) {
  if (searchText) {
    usersLoading.value = true;

    const filter = [
      {
        fieldName: "name",
        contains: searchText,
      },
      {
        fieldName: "department",
        contains: searchText,
      },
      {
        fieldName: "email",
        contains: searchText,
      },
      {
        fieldName: "firstname",
        contains: searchText,
      },
      {
        fieldName: "lastname",
        contains: searchText,
      },
      {
        fieldName: "middlename",
        contains: searchText,
      },
      {
        fieldName: "function",
        contains: searchText,
      },
    ];

    const response = await getUsers(0, 15, undefined, undefined, filter, false, true);
    console.log("ContactRoleSelection getUsers: ", response, filter);
    if (response && !response.error) {
      const items = response.data.data.map((el) => ({ ...el, id: el.id, name: el.name, description: makeUserDescription(el.department, el.email) }));

      userItems.value = items;
    } else {
      userItems.value = [];
    }
    usersLoading.value = false;
  }
}

function makeUserDescription(department, email) {
  let description = department ? department : "";
  if (email) {
    if (department) {
      description += " : ";
    }
    description += email;
  }
  return description;
}

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function loadPartyRoles() {
  //console.log("ContactRoleSelection, loadPartyRoles ");
  const response = await getPartyRoles();
  if (response && !response.error) {
    response.data.keyDescList.sort((a, b) => b.prio - a.prio);
    partyRoles.value = [...response.data.keyDescList];
    //console.log("ContactRoleSelection, loadPartyRoles ", partyRoles.value);
    partyRolesLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de rollen is mislukt! " + response.error);
  }
}

function onClear() {
  userItems.value = [];
}

function addContact() {
  emits("on-add-contact", { roleKey: role.value.key, roleDescription: role.value.description, userId: selectedUser.value.id, userDescription: selectedUser.value.name });
}
</script>

<template>
  <div>
    <v-row>
      <v-col cols="9">
        <v-select
          hide-details="auto"
          label="Rol"
          persistent-placeholder
          :items="partyRoles"
          item-text="description"
          item-value="key"
          v-model="role"
          return-object
          :outlined="!readOnly"
          :class="{ noBorders: readOnly }"
          :readonly="readOnly"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-autocomplete
          v-model="selectedUser"
          ref="selector"
          :items="userItems"
          :search-input.sync="searchUsers"
          label="Gebruiker"
          hide-details="auto"
          hide-no-data
          :loading="usersLoading"
          item-text="name"
          item-value="id"
          placeholder="Zoek actieve gebruiker..."
          persistent-placeholder
          return-object
          prepend-inner-icon="mdi-magnify"
          clearable
          loader-height="4"
          :outlined="!readOnly"
          :readonly="readOnly"
          :class="{ noBorders: readOnly }"
          :menu-props="menuProps"
          @click.clear="onClear"
        >
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
              <v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="3" align="center">
        <v-btn v-if="!readOnly" :disabled="addDisabled" :dark="!addDisabled" class="primary mt-3" @click="addContact">
          <v-icon dark center>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
